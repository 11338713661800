const menu = [
  // {title: 'home', target: 'Home', icon: 'mdi-home'},
  {title: 'receipts', target: 'ReceiptList', icon: 'mdi-package-variant-closed'},
  {title: 'parcels', target: 'ParcelList', icon: 'mdi-barcode'},
  {title: 'consolidations', target: 'ConsolidationList', icon: 'mdi-list-box-outline'},
  {title: 'orders', target: 'OrderList', icon: 'mdi-truck-fast-outline'},
  {title: 'assignments', target: 'AssignmentList', icon: 'mdi-checkbox-marked-circle-outline'},
  {title: 'statistics', target: 'StatisticsReportList', icon: 'mdi-google-analytics', roles: ['ROLE_VIEW_STATISTICS']},
  {title: 'admin', target: 'Admin', icon: 'mdi-cog', roles: ['ROLE_ADMIN']},
];

export default menu;
