<template>
  <div>
    <v-btn fab
           depressed
           small
           text-color="white"
           :color="color"
           class="hidden-sm-and-up">
      XS
    </v-btn>
    <v-btn fab
           depressed
           small
           text-color="white"
           :color="color"
           class="hidden-xs-only hidden-md-and-up">
      SM
    </v-btn>
    <v-btn fab
           depressed
           small
           text-color="white"
           :color="color"
           class="hidden-sm-and-down hidden-lg-and-up">
      MD
    </v-btn>
    <v-btn fab
           depressed
           small
           text-color="white"
           :color="color"
           class="hidden-md-and-down hidden-xl-only">
      LG
    </v-btn>
    <v-btn fab
           depressed
           small
           text-color="white"
           :color="color"
           class="hidden-lg-and-down">
      XL
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'CurrentSize',
  props: {
    color: {
      type: String,
      default: 'secondary',
    },
  },
};
</script>

<style lang="sass"
       scoped>

</style>
