import i18n from '@/i18n';
import Vue from 'vue';
import {getValue} from '@/utils/PathAccessor';

const ServerErrorBag = function () {
  return {
    children: {},
    errors: [],
    clear () {
      this.children = {};
      this.errors   = [];
    },
    addError (message) {
      this.errors.push(message);
    },
    addChild (field, errorBag) {
      Vue.set(this.children, field, errorBag);
    },
    getChild (path) {
      if (typeof path === 'undefined' || path.length === 0) {
        return this;
      } else {
        if (path instanceof Array) {
          path = [...path];
        } else {
          path = String(path).split('.');
        }
        const key = path.shift();
        if (typeof this.children[key] === 'undefined') {
          return new ServerErrorBag();
        }
        return this.children[key]
          .getChild(path);
      }
    },
    collect (path) {
      return this.getChild(path).errors;
    },
    get (path) {
      return this.getChild(path).errors;
    },
    hasErrorsRecursive (path) {
      function recursiveChecker (bag) {
        if (bag.errors.length > 0) return true;

        for (const child in bag.children) {
          if (
            Object.prototype.hasOwnProperty.call(bag.children, child)
            && recursiveChecker(bag.children[child])
          ) {
            return true;
          }
        }
        return false;
      }

      const bag = this.getChild(path);
      return recursiveChecker(bag);
    },
  };
};

function recursiveParser (serverErrors, errorBag) {
  if (serverErrors.children) {
    for (const child in serverErrors.children) {
      if (Object.prototype.hasOwnProperty.call(serverErrors.children, child)) {
        const recursiveErrorBag = new ServerErrorBag();
        errorBag.addChild(child, recursiveErrorBag);
        recursiveParser(serverErrors.children[child], recursiveErrorBag);
      }
    }
  }
  if (Object.prototype.hasOwnProperty.call(serverErrors, 'errors')) {
    for (const key in serverErrors.errors) {
      if (Object.prototype.hasOwnProperty.call(serverErrors.errors, key)) {
        errorBag.addError(
          i18n.te('validation.messages[\'' + serverErrors.errors[key] + '\']')
            ? i18n.t('validation.messages[\'' + serverErrors.errors[key] + '\']')
            : serverErrors.errors[key],
        );
      }
    }
  }
}

function validationParser (error = undefined, bag = new ServerErrorBag()) {
  if (getValue(error, 'response.data.message') === 'Validation Failed') {
    recursiveParser(error.response.data.errors, bag);
  } else if (typeof error !== 'undefined' && getValue(error, 'response.data') === undefined) {
    throw error;
  }
  return bag;
}

const install = {
  install: function (Vue) {
    Vue.prototype.$serverValidationParser = validationParser;
  },
};

Vue.use(install);
