import Vue from 'vue';
import Router from 'vue-router';
import routes from '@/router/routes';
import i18n from '@/i18n';
import {isFunction} from 'lodash';
import store from '@/store';

Vue.use(Router);

const router = new Router({
  /*
 * NOTE! VueRouter "history" mode DOESN'T works for Cordova builds,
 * it is only to be used only for websites.
 *
 * If you decide to go with "history" mode, please also open /config/index.js
 * and set "build.publicPath" to something other than an empty string.
 * Example: '/' instead of current ''
 *
 * If switching back to default "hash" mode, don't forget to set the
 * build publicPath back to '' so Cordova builds work again.
 */
  mode: 'history',
  routes: [
    ...routes,
    {
      path: '*',
      // component: load('Error404'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.commit('routeState/addRouteToHistory', from);
  if (
    store.state.auth.user
    && to.meta.roles
    && !store.getters['auth/hasRole'](to.meta.roles)
  ) {
    router.push({name: 'AccessDeniedPage'});
  }
  next();
});

router.afterEach(route => {
  if (route.meta.title) {
    const title    = isFunction(route.meta.title) ? route.meta.title(route.params) : route.meta.title;
    document.title = `${i18n.te(title) ? i18n.t(title) : title} | Parcels`;
  } else {
    document.title = 'Parcels';
  }
});

export default router;
