const routes = [
  {
    path: '/',
    exact: true,
    redirect: '/receipts',
  },
  {
    path: '/orders',
    name: 'OrderList',
    component: () => import(/* webpackChunkName: "Orders" */ '@/views/Orders/OrderList.vue'),
    exact: true,
    meta: {
      title: 'orders',
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Admin/Admin.vue'),
    exact: true,
    meta: {
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home/HomePage'),
    exact: true,
  },
  {
    path: '/receipts',
    name: 'ReceiptList',
    component: () => import(/* webpackChunkName: "Receipts" */ '@/views/Receipts/ReceiptList.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'receipts',
    },
  },
  {
    path: '/receipts/new',
    name: 'ReceiptCreate',
    component: () => import(/* webpackChunkName: "Receipts" */ '@/views/Receipts/ReceiptCreate.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'newReceipt',
    },
  },
  {
    path: '/receipts/:id',
    name: 'ReceiptProperties',
    component: () => import(/* webpackChunkName: "Receipts" */ '@/views/Receipts/ReceiptProperties.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'receipt',
    },
  },
  {
    path: '/parcels',
    name: 'ParcelList',
    component: () => import(/* webpackChunkName: "Parcels" */ '@/views/Parcels/ParcelList.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'parcels',
    },
  },
  {
    path: '/receipts/:receiptId/parcels/new',
    name: 'ParcelCreate',
    component: () => import(/* webpackChunkName: "Parcels" */ '@/views/Parcels/ParcelCreate.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'newParcel',
    },
  },
  {
    path: '/parcels/:id',
    name: 'ParcelProperties',
    component: () => import(/* webpackChunkName: "Parcels" */ '@/views/Parcels/ParcelProperties.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'parcel',
    },
    redirect: '/parcels',
  },
  {
    path: '/users',
    name: 'UserList',
    component: () => import(/* webpackChunkName: "Users" */ '@/views/Users/UserList'),
    exact: true,
    meta: {
      title: 'users',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/users/new',
    name: 'UserCreate',
    component: () => import(/* webpackChunkName: "Users" */ '@/views/Users/UserCreate'),
    props: true,
    exact: true,
    meta: {
      title: 'newUser',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/users/:id',
    name: 'UserProperties',
    component: () => import(/* webpackChunkName: "Users" */ '@/views/Users/UserProperties.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'editUser',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/consolidations',
    name: 'ConsolidationList',
    component: () => import(/* webpackChunkName: "Consolidations" */ '@/views/Consolidations/ConsolidationList'),
    exact: true,
    meta: {
      title: 'consolidations',
    },
  },
  {
    path: '/consolidations/new',
    name: 'ConsolidationCreate',
    component: () => import(/* webpackChunkName: "Consolidations" */ '@/views/Consolidations/ConsolidationCreate.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'newConsolidation',
    },
  },
  {
    path: '/consolidations/:id/set-return',
    name: 'ConsolidationSetReturn',
    component: () => import(/* webpackChunkName: "Consolidations" */ '@/views/Consolidations/ConsolidationSetReturn.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'setReturnConsolidation',
    },
  },
  {
    path: '/consolidations/:id',
    name: 'ConsolidationProperties',
    component: () => import(/* webpackChunkName: "Consolidations" */ '@/views/Consolidations/ConsolidationProperties.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'consolidation',
    },
  },
  {
    path: '/consolidations/:id/update-status',
    name: 'ConsolidationUpdateParcelCollectionStatus',
    component: () => import(/* webpackChunkName: "Consolidations" */ '@/views/Consolidations/ConsolidationUpdateParcelCollectionStatus.vue'),
    props: true,
    exact: true,
  },
  {
    path: '/delivery-services',
    name: 'DeliveryServiceList',
    component: () => import(/* webpackChunkName: "DeliveryServices" */ '@/views/DeliveryServices/DeliveryServiceList'),
    exact: true,
    meta: {
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/additional-settings',
    name: 'AdditionalSettingsList',
    component: () => import(/* webpackChunkName: "DeliveryServices" */ '@/views/AdditionalSettings/AdditionalSettingsList.vue'),
    exact: true,
    meta: {
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/statistics-reports',
    name: 'StatisticsReportList',
    component: () => import(/* webpackChunkName: "DeliveryServices" */ '@/views/Statistics/StatisticsReportList.vue'),
    exact: true,
    meta: {
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/delivery-services/new',
    name: 'DeliveryServiceCreate',
    component: () => import(/* webpackChunkName: "DeliveryServices" */ '@/views/DeliveryServices/DeliveryServiceCreate.vue'),
    props: true,
    exact: true,
    meta: {
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/delivery-services/:id',
    name: 'DeliveryServiceProperties',
    component: () => import(/* webpackChunkName: "DeliveryServices" */ '@/views/DeliveryServices/DeliveryServiceProperties.vue'),
    props: true,
    exact: true,
    meta: {
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/access-denied',
    name: 'AccessDeniedPage',
    component: () => import(/* webpackChunkName: "AccessDenied" */ '@/views/AccessDeniedPage/AccessDeniedPage.vue'),
    props: true,
    exact: true,
  },
  {
    path: '/parcel-status-types',
    name: 'ParcelStatusTypeList',
    component: () => import(/* webpackChunkName: "ParcelStatusTypes" */ '@/views/ParcelStatusTypes/ParcelStatusTypeList'),
    exact: true,
    meta: {
      title: 'parcelStatusTypes',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/parcel-status-types/new',
    name: 'ParcelStatusTypeCreate',
    component: () => import(/* webpackChunkName: "ParcelStatusTypes" */ '@/views/ParcelStatusTypes/ParcelStatusTypeCreate'),
    props: true,
    exact: true,
    meta: {
      title: 'newParcelStatusType',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/parcel-status-types/:id',
    name: 'ParcelStatusTypeProperties',
    component: () => import(/* webpackChunkName: "ParcelStatusTypes" */ '@/views/ParcelStatusTypes/ParcelStatusTypeProperties.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'parcelStatusType',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/counterparties',
    name: 'CounterpartyList',
    component: () => import(/* webpackChunkName: "Counterparties" */ '@/views/Counterparties/CounterpartyList'),
    exact: true,
    meta: {
      title: 'counterparties',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/counterparties/new',
    name: 'CounterpartyCreate',
    component: () => import(/* webpackChunkName: "Counterparties" */ '@/views/Counterparties/CounterpartyCreate'),
    props: true,
    exact: true,
    meta: {
      title: 'newCounterparty',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/counterparties/:id',
    name: 'CounterpartyProperties',
    component: () => import(/* webpackChunkName: "Counterparties" */ '@/views/Counterparties/CounterpartyProperties.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'editCounterparty',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/user-groups',
    name: 'UserGroupList',
    component: () => import(/* webpackChunkName: "UserGroups" */ '@/views/UserGroups/UserGroupList'),
    exact: true,
    meta: {
      title: 'userGroups',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/user-groups/new',
    name: 'UserGroupCreate',
    component: () => import(/* webpackChunkName: "UserGroups" */ '@/views/UserGroups/UserGroupCreate'),
    props: true,
    exact: true,
    meta: {
      title: 'newUserGroup',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/user-groups/:id',
    name: 'UserGroupProperties',
    component: () => import(/* webpackChunkName: "UserGroups" */ '@/views/UserGroups/UserGroupProperties.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'userGroup',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: '/assignments',
    name: 'AssignmentList',
    component: () => import(/* webpackChunkName: "Assignments" */ '@/views/Assignments/AssignmentList'),
    exact: true,
    meta: {
      title: 'assignments',
    },
  },
  {
    path: '/assignments/new',
    name: 'AssignmentParcelCreate',
    component: () => import(/* webpackChunkName: "Assignments" */ '@/views/Assignments/AssignmentCreate'),
    props: true,
    exact: true,
    meta: {
      title: 'newAssignment',
    },
  },
  {
    path: '/consolidations/:id/assignments/new',
    name: 'AssignmentConsolidationCreate',
    component: () => import(/* webpackChunkName: "Assignments" */ '@/views/Assignments/AssignmentCreate'),
    props: true,
    exact: true,
    meta: {
      title: 'newAssignment',
    },
  },
  {
    path: '/assignments/:id',
    name: 'AssignmentProperties',
    component: () => import(/* webpackChunkName: "Assignments" */ '@/views/Assignments/AssignmentProperties.vue'),
    props: true,
    exact: true,
    meta: {
      title: 'assignment',
    },
  },
];

export default routes;
export {routes};
