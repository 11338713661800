function camelToSnakeCase (str) {
  if (typeof str === 'undefined') {
    return '---';
  }
  return   str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

function camelToKebabCase (str) {
  if (typeof str === 'undefined') {
    return '---';
  }
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export {camelToSnakeCase, camelToKebabCase};
