/* eslint-disable no-undef */
const API_URL           = SYSTEM_CONFIG.API_URL;
const TEST_ENVIRONMENT  = SYSTEM_CONFIG.TEST_ENVIRONMENT;
const INSTANCE          = SYSTEM_CONFIG.INSTANCE || 'unknown';
const ANALYTICS_ENABLED = SYSTEM_CONFIG.ANALYTICS_ENABLED;

/* eslint-enable no-undef */
export {
  API_URL,
  TEST_ENVIRONMENT,
  INSTANCE,
  ANALYTICS_ENABLED,
};
