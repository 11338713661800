const state = {
  tableStates: {},
  searchBy: {},
};

const mutations = {
  setState: (state, data) => {
    state.tableStates[data.id] = data.state;
  },
  setSearchBy: (state, data) => {
    state.searchBy[data.id] = data.state;
  },
};

const actions = {};

const getters = {
  getState: state => {
    return (key) => {
      return state.tableStates[key] === undefined ? {} : state.tableStates[key];
    };
  },
  getSearchBy: state => {
    return (key) => {
      return state.searchBy[key] === undefined ? {} : state.searchBy[key];
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
