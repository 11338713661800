<template>
  <v-snackbar v-model="snackbarDisplayed"
              :timeout="snackbar.current.timeout"
              :color="snackbar.current.color"
              :multi-line="snackbar.current.content > 60"
              bottom
              :left="snackbar.current.position==='left'"
              :right="snackbar.current.position==='right'">
    <v-icon v-if="snackbar.current.icon"
            :color="snackbar.current.iconColor"
            class="pr-1">
      {{snackbar.current.icon}}
    </v-icon>
    <template v-if="$te(snackbar.current.content)">
      {{$t(snackbar.current.content, snackbar.current.parameters)}}
    </template>
    <template v-else>
      {{snackbar.current.fallback}}
    </template>
    <template v-slot:action="{attrs}">
      <v-btn text
             dark
             v-bind="attrs"
             @click.native="snackbarDisplayed = false">
        {{$t('close')}}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {
  mapMutations,
  mapState,
  mapActions,
} from 'vuex';

export default {
  name: 'SnackbarCollection',
  data () {
    return {};
  },
  computed: {
    ...mapState({
      snackbar: state => state.snackbar,
    }),
    snackbarDisplayed: {
      get () {
        return this.snackbar.show;
      },
      set (value) {
        if (value) this.showSnackbar();
        else this.hideSnackbar();
      },
    },
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      showSnackbar: 'snackbar/show',
      hideSnackbar: 'snackbar/hide',
    }),
  },
};
</script>

<style lang="sass"
       scoped>

</style>
