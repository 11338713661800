import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from '@/router';
import API from '@/plugins/Api';
import i18n from '@/i18n';
import store from '@/store';
import isGranted from '@/plugins/IsGranted';
import getValue from '@/plugins/GetValue';
import '@mdi/font/css/materialdesignicons.css';
import '@/filters';
import '@/plugins/ServerValidationParser';
import VueGtm from '@gtm-support/vue2-gtm';
import '@/styles/main.sass';

Vue.config.productionTip = false;
Vue.prototype.API        = API;
Vue.config.productionTip = false;

Vue.use(isGranted);
Vue.use(getValue);
Vue.use(VueGtm, {
  id: 'GTM-W6VV7R6',
  // queryParams: {
  // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
  // gtm_auth: '',
  // gtm_preview: 'env-4',
  // gtm_cookies_win: 'x',
  // },
  defer: false,
  compatibility: false,
  // nonce: '',
  enabled: false,
  debug: false,
  loadScript: true,
  // vueRouter: router,
  // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false,
});

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
