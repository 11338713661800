<template>
  <v-btn v-bind="$attrs"
         :loading="loading"
         class="rounded-lg"
         @click="onClick"
         v-on="$listeners">
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'ApiBtn',
  props: {
    apiUrl: {
      type: [String, Array],
      default: undefined,
    },
    apiMethod: {
      type: String,
      default: 'post',
    },
    apiData: {
      type: [Object, Array],
      default: undefined,
    },
    successMessage: {
      type: String,
      default: undefined,
    },
    successMessageColor: {
      type: String,
      default: 'success',
    },
    preApiCallCallback: {
      type: Function,
      default: undefined,
    },
  },
  emits: ['success', 'error', 'preApiCall'],
  data () {
    return {
      loading: false,
    };
  },
  methods: {
    async submit () {
      await this.onClick();
    },
    async onClick (depth = 0) {
      let retry    = false;
      this.loading = true;
      this.$emit('preApiCall');
      if (typeof this.preApiCallCallback !== 'undefined' && !await this.preApiCallCallback()) {
        this.loading = false;
        return;
      }
      try {
        const success = await this.API[this.apiMethod](this.apiUrl, this.apiData);
        if (success.status === 202) {
          if (depth <= 3) {
            retry = true;
            await this.$store.dispatch('snackbar/add', {
              color: 'warning',
              content: 'apiErrors[\'' + success.data.message + '\']',
              timeout: 4500,
            });
            setTimeout(() => {
              this.onClick(depth + 1);
            }, 5000);
          } else {
            await this.$store.dispatch('snackbar/add', {
              color: 'warning',
              content: 'apiErrors[\'Your request taking to much time, try refreshing page later.\']',
              timeout: 4500,
            });
            this.$emit('error');
          }
        } else {
          this.$emit('success', success.data);
          if (this.successMessage) {
            await this.$store.dispatch('snackbar/add', {
              color: this.successMessageColor, content: this.successMessage,
            });
          }
          if (this.deleteMessage) {
            await this.$store.dispatch('snackbar/add', {
              color: this.successMessageColor, content: this.successMessage,
            });
          }
        }
      } catch (e) {
        this.$emit('error', e);
      } finally {
        if (!retry) {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="sass"
       scoped>

</style>
