<template>
  <v-app :dark="$store.state.options.darkTheme">
    <template v-if="$store.state.auth.user && !$store.state.auth.showLogin">
      <app-left-menu />
      <app-header />
      <v-main style="background-color: #f5f7fa;">
        <router-view :key="$route.path" />
      </v-main>
    </template>
    <login-page v-if="$store.state.auth.showLogin"
                style="background-color: #f5f7fa;" />
    <snackbar-collection />
  </v-app>
</template>

<script>

import AppHeader from '@/components/Navigation/AppHeader';
import SnackbarCollection from '@/components/SnackbarCollection';
import AppLeftMenu from '@/components/Navigation/LeftMenu/AppLeftMenu';
import LoginPage from '@/views/LoginPage/LoginPage.vue';
import {ANALYTICS_ENABLED, INSTANCE} from '@/config';

export default {
  name: 'App',
  components: {LoginPage, AppLeftMenu, SnackbarCollection, AppHeader},
  async created () {
    try {
      await this.init();
    } finally {
      this.initGoogleAnalytics();
    }
  },
  methods: {
    async init () {
      const success = await this.API.get(['system-options']);
      await this.$store.commit('auth/setUser', success.data.user);
    },
    initGoogleAnalytics () {
      if (ANALYTICS_ENABLED) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({environment: INSTANCE});
        this.$gtm.enable(true);
      }
    },
  },
};
</script>
