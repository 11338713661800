import Vue from 'vue';
import Vuex from 'vuex';
import cache from '@/store/modules/Cache';
import snackbar from '@/store/modules/Snackbar';
import options from '@/store/modules/Options';
import auth from '@/store/modules/Auth';
import createPersistedState from 'vuex-persistedstate';
import tableState from '@/store/modules/TableState';
import routeState from '@/store/modules/RouteState';

const isDev = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

const store = new Vuex.Store({
  // actions,
  // getters,
  // mutations,
  modules: {
    cache,
    snackbar,
    options,
    auth,
    tableState,
    routeState,
  },
  strict: isDev,
  plugins: [
    createPersistedState({
      key: 'parcels',
      paths: [
        'tableState',
        'auth',
      ],
    }),
  ],
});

export {store};
export default store;
