import {intersection} from 'lodash';
import {ANALYTICS_ENABLED} from '@/config';

const state = {
  user: undefined,
  showLogin: false,
  roles: [],
};

function initGoogleAnalytics (user) {
  if (!ANALYTICS_ENABLED) return;
  window.dataLayer = window.dataLayer || [];
  if (user?.id) {
    const userData = user.email.split('@');
    window.dataLayer.push({visitor: userData[0]});
    window.dataLayer.push({client: user.counterparty.name});
  } else {
    window.dataLayer.push({visitor: undefined});
    window.dataLayer.push({client: undefined});
  }
}

const mutations = {
  setUser (state, data) {
    state.roles = data ? data.roles : [];
    initGoogleAnalytics(data);
    state.user = data;
  },
  setShowLogin (state, data) {
    state.showLogin = data;
  },
};

const actions = {};
const getters = {
  hasRole: state => {
    return (roles) => {
      if (!state.user) {
        return false;
      }

      let collection;
      if (Array.isArray(roles)) {
        collection = roles;
      } else {
        collection = roles === undefined ? [] : [roles];
      }
      return collection.length === 0 || intersection(state.roles, collection).length > 0;
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
