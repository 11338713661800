import axios from 'axios';
import store from '@/store';
import {API_URL} from '@/config';
import {getValue} from '@/utils/PathAccessor';

const types          = ['get', 'post', 'put', 'patch', 'delete'];
const defaultHeaders = {'Content-Type': 'application/json'};

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: defaultHeaders,
  transformRequest: [function (data) {
    return data instanceof FormData ? data : JSON.stringify(data);
  }],
});

// async function updateToken () {
//   try {
//     await store.state.auth.keycloak.updateToken(60);
//   } catch (e) {
//     console.error('Failed to refresh the token, or the session has expired');
//   }
// }

axiosInstance.interceptors.request.use(
  async (config) => {
    // if (store.state.auth.keycloak.authenticated) {
    //   await updateToken();
    // }
    // config.headers.Authorization = `Bearer ${store.state.auth.keycloak.token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    if (error.response && error.response.status === 401) {
      store.commit('auth/setShowLogin', true);
      store.commit('auth/setUser', undefined);
      return Promise.reject(error);
      // const back = window.location.pathname;
      // location.replace(`${AUTH_URL}?redirect=${back}`);
    }

    if (store.state.auth.showLogin) {
      return Promise.reject(error);
    }

    const toastOptions = {
      timeout: 5000,
      color: 'error',
    };

    if (error.response && error.response.status === 403) {
      toastOptions.timeout = 10000;
    }

    const serverExceptionMessage = getValue(error, 'response.data.message')
      || getValue(error, 'response.data.error.exception.0.message')
      || getValue(error, 'response.data.error.message')
    ;

    if (serverExceptionMessage) {
      toastOptions.content    = 'apiErrors[\'' + serverExceptionMessage + '\']';
      toastOptions.parameters = {status: error.response.data.code};
      toastOptions.fallback   = serverExceptionMessage;
    } else {
      toastOptions.content  = 'apiErrors[\'' + error.message + '\']';
      toastOptions.fallback = error.message;
    }

    await store.dispatch('snackbar/add', toastOptions);

    console.error(error);
    if (error.response.headers['x-debug-token-link']) {
      console.error(error.response.headers['x-debug-token-link']);
    }
    return Promise.reject(error);
  },
);

function constructRoute (route) {
  let result;
  if (route instanceof Array) {
    result = '/' + route.join('/');
  } else {
    result = route;
  }

  return result;
}

function constructFullRoute (route) {
  return API_URL + constructRoute(route);
}

function getOptions (params = {}, options = {}, cancelToken) {
  options = {
    ...options,
    params,
    headers: {
      ...options.headers,
    },
    transformResponse: [function (data) {
      try {
        const response = JSON.parse(data);
        return Object.prototype.hasOwnProperty.call(response, 'data') ? response.data : response;
      } catch (e) {
        return data;
      }
    }],
  };

  if (typeof cancelToken !== 'undefined') {
    options.cancelToken = cancelToken.token;
  }

  return options;
}

const API = {
  // getToken () {
  //   return store.state.auth.keycloak.token;
  // },
  // updateToken,
  constructFullRoute,
  constructRoute,
  getBaseUrl () {
    return API_URL;
  },
  get (url, params = {}, options = {}, cancelToken) {
    return axiosInstance.get(constructRoute(url), getOptions(params, options, cancelToken));
  },
  post (url, data, options = {}, cancelToken) {
    return axiosInstance.post(constructRoute(url), data, getOptions({}, options, cancelToken));
  },
  put (url, data, options = {}, cancelToken) {
    return axiosInstance.put(constructRoute(url), data, getOptions({}, options, cancelToken));
  },
  patch (url, data, options = {}, cancelToken) {
    return axiosInstance.patch(constructRoute(url), data, getOptions({}, options, cancelToken));
  },
  delete (url, params = {}, options = {}, cancelToken) {
    return axiosInstance.delete(constructRoute(url), getOptions(params, options, cancelToken));
  },

  postWithoutAuth (url, data) {
    return axiosInstance.post(constructRoute(url), data);
  },

  createCancelToken () {
    return axios.CancelToken.source();
  },
};

API.getCancelingApi = function () {
  const cancelingApi = {
    cancelRequest: undefined,
  };

  types.forEach((type) => {
    cancelingApi[type] = async function (url, data = {}, options = {}) {
      if (cancelingApi.cancelRequest !== undefined) {
        cancelingApi.cancelRequest.cancel();
      }
      cancelingApi.cancelRequest = API.createCancelToken();
      const result               = await API[type](url, data, options, cancelingApi.cancelRequest);
      cancelingApi.cancelRequest = undefined;
      return result;
    };
  });

  return cancelingApi;
};

export default API;
