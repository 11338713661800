<template>
  <v-container fluid
               class="justify-center fill-height">
    <v-row dense
           justify="center">
      <v-col style="max-width:420px;">
        <v-card outlined
                flat
                class="rounded-lg pa-3">
          <v-card-title class="mt-6 mb-9 d-flex justify-center">
            <v-img :src="logo"
                   max-width="300" />
            <!-- <div class="text-h6 text-md-h5 font-weight-bold ml-3">
              <span style="color:#012169;">TRANSPORTIR.</span>
              <span style="color:#FE5000;">Parcels</span>
            </div> -->
          </v-card-title>
          <v-card-text>
            <v-row @keydown.enter.capture.stop="$refs.loginBtn.submit()">
              <v-col cols="12">
                <v-text-field v-model="model.username"
                              hide-details
                              flat
                              dense
                              solo
                              name="login"
                              background-color="#f5f7fa"
                              class="rounded-lg"
                              :label="$t('email')">
                  <template v-slot:prepend-inner>
                    <v-icon class="mr-2">
                      {{'mdi-email-outline'}}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="model.password"
                              hide-details
                              flat
                              dense
                              solo
                              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPassword ? 'text' : 'password'"
                              background-color="#f5f7fa"
                              class="rounded-lg"
                              :label="$t('password')"
                              @click:append="showPassword = !showPassword">
                  <template v-slot:prepend-inner>
                    <v-icon class="mr-2">
                      {{'mdi-lock-outline'}}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="errorMessage">
              <v-col class="error--text">
                {{$t('apiErrors[\'' + errorMessage + '\']')}}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <api-btn ref="loginBtn"
                         block
                         color="primary"
                         api-method="post"
                         class="rounded-lg mt-3"
                         :api-data="model"
                         :api-url="['login']"
                         @success="onLoggedIn"
                         @error="onError">
                  {{$t('login')}}
                </api-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiBtn from '@/components/ApiBtn.vue';
import logo from '@/assets/logo_text.svg';

export default {
  name: 'LoginPage',
  components: {ApiBtn},
  data () {
    return {
      model: {
        username: undefined,
        password: undefined,
      },
      errorMessage: undefined,
      logo,
      showPassword: false,
    };
  },
  methods: {
    async onLoggedIn () {
      const success = await this.API.get(['system-options']);
      await this.$store.commit('auth/setUser', success.data.user);
      if (
        this.$route.meta.roles
        && !this.$isGranted(this.$route.meta.roles)
      ) {
        await this.$router.push({name: 'AccessDeniedPage'});
      }
      this.$store.commit('auth/setShowLogin', false);
    },
    onError (data) {
      this.errorMessage = data.response.data.error;
    },
  },
};
</script>

<style scoped>

</style>
