import Vue from 'vue';

// import {timeAgo} from '@/filters/timeAgo';
import {dateShort, dateCustom, explodeSeconds, dateTimeShort, timeShort} from '@/filters/dateFormat';
import {finance, id, fileSize, padZeroes} from '@/filters/number';
import {currencySymbol} from '@/filters/currencySymbol';
import {getValue} from '@/utils/PathAccessor';
import {camelToSnakeCase, camelToKebabCase} from '@/filters/stringCase';

// Vue.filter('timeAgo', timeAgo);
Vue.filter('dateShort', dateShort);
Vue.filter('dateCustom', dateCustom);
Vue.filter('finance', finance);
Vue.filter('id', id);
Vue.filter('padZeroes', padZeroes);
Vue.filter('fileSize', fileSize);
Vue.filter('currencySymbol', currencySymbol);
Vue.filter('explodeSeconds', explodeSeconds);
Vue.filter('dateTimeShort', dateTimeShort);
Vue.filter('timeShort', timeShort);
Vue.filter('accessor', getValue);
Vue.filter('camelToSnakeCase', camelToSnakeCase);
Vue.filter('camelToKebabCase', camelToKebabCase);
