const state = {
  routeHistory: [],
};

const mutations = {
  addRouteToHistory (state, route) {
    state.routeHistory.push(route);
  },
  popRouteFromHistory (state) {
    state.routeHistory.pop();
  },
};

const actions = {};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
