<template>
  <v-navigation-drawer v-model="drawer"
                       app
                       :mini-variant="$vuetify.breakpoint.mdAndUp"
                       mini-variant-width="100"
                       width="180">
    <v-list nav
            class="pt-1">
<!--      <v-list-item v-if="$store.state.routeState.routeHistory.length > 1"-->
<!--                   class="mb-3"-->
<!--                   link-->
<!--                   exact-->
<!--                   @click="pushBack">-->
<!--        <v-list-item-icon class="d-md-flex flex-md-column justify-center mx-0 mb-0">-->
<!--          <v-icon>{{'mdi-keyboard-return'}}</v-icon>-->
<!--        </v-list-item-icon>-->
<!--        <v-list-item-content class="text-no-wrap" />-->
<!--      </v-list-item>-->
      <v-list-item class="mb-3">
        <v-list-item-icon>
          <v-icon>{{'mdi-'}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-no-wrap" />
      </v-list-item>
      <v-list-item v-for="(item, index) in menuCollection"
                   :key="index"
                   link
                   exact
                   :to="{name: item.target}"
                   @click="() => {}">
        <v-list-item-icon class="d-md-flex flex-md-column justify-center mx-0">
          <v-icon>{{item.icon}}</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp"
                class="text-caption">
            {{$t(item.title)}}
          </span>
        </v-list-item-icon>
        <v-list-item-content class="ml-3">
          <v-list-item-title>
            {{$t(item.title)}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import menuCollection from '@/menu';
import {mapMutations} from 'vuex';

export default {
  name: 'AppLeftMenu',
  data () {
    return {};
  },
  computed: {
    menuCollection () {
      return menuCollection.filter((item) => {
        return !item.roles || this.$isGranted(item.roles);
      });
    },
    drawer: {
      get () {
        return this.$store.state.options.drawerOpen;
      },
      set (val) {
        this.setDrawer(val);
      },
    },
  },
  methods: {
    ...mapMutations({
      setDrawer: 'options/setDrawer',
      popRouteFromHistory: 'routeState/popRouteFromHistory',
    }),
    pushBack () {
      this.popRouteFromHistory();
      this.$router.back();
      this.popRouteFromHistory();
    },
  },
};
</script>

<style lang="sass"
       scoped>
</style>
