<template>
  <v-app-bar app
             :color="isTestEnvironment ? 'red darken-4' :''"
             elevation="0"
             outlined
             style="background-color: white;">
    <!--                 clipped-left-->
    <v-img :src="logo"
           max-height="32"
           max-width="32" />
    <v-toolbar-title class="pl-3 font-weight-black"
                     :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'text-h6'">
      <span v-if="$vuetify.breakpoint.mdAndUp"
            style="color:#012169;">TRANSPORTIR </span>
      <span style="color:#FE5000;">Parcels</span>
    </v-toolbar-title>
    <v-row dense
           align="center">
      <v-col />
      <v-col v-if="isTestEnvironment"
             class="flex-grow-0">
        <v-row class="flex-nowrap"
               align="center">
          <v-col class="flex-grow-0">
            <v-icon large>
              mdi-alert
            </v-icon>
          </v-col>
          <v-col>
            TEST ENVIRONMENT
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="isDev"
             class="flex-grow-0">
        <current-size color="primary" />
      </v-col>
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown"
                          @click.stop="drawer = !drawer" />
      <v-col class="flex-grow-0">
        <logout-button />
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import {mapMutations} from 'vuex';
import CurrentSize from '@/components/Navigation/Header/CurrentSize';
import {TEST_ENVIRONMENT} from '@/config';
import LogoutButton from '@/components/Navigation/Header/LogoutButton.vue';
import logo from '@/assets/logo.svg';

export default {
  name: 'AppHeader',
  components: {LogoutButton, CurrentSize},
  data () {
    return {
      logo,
    };
  },
  computed: {
    isDev () {
      return process.env.NODE_ENV === 'development';
    },
    isTestEnvironment () {
      return TEST_ENVIRONMENT;
    },
    client: {
      get () {
        return this.$store.getters['mode/client'];
      },
    },
    drawer: {
      get () {
        return this.$store.state.options.drawerOpen;
      },
      set (val) {
        this.setDrawer(val);
      },
    },
  },
  methods: {
    ...mapMutations({
      darkMode: 'options/switchDarkTheme',
      setDrawer: 'options/setDrawer',
    }),
  },
};
</script>

<style scoped>

</style>
