<template>
  <v-btn text
         icon
         @click="onClick">
    <v-icon>
      mdi-exit-to-app
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'LogoutButton',
  methods: {
    async onClick () {
      await this.API.get(['system-options', 'logout']);
      this.$store.commit('auth/setUser', undefined);
      this.$store.commit('auth/setShowLogin', true);
    },
  },
};
</script>

<style scoped>

</style>
