import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from '@/i18n';
import store from '@/store';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#012169',
      },
    },
  },
  lang: {
    current: store.state.options.localeShort,
    t: (key, ...params) => i18n.t(key, params),
  },
});
