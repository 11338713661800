function finance (value, accuracy, grouping = false) {
  typeof accuracy === 'undefined' && (accuracy = 2);
  isNaN(value) && (value = 0);
  return Number(value)
    .toLocaleString(undefined, {
      minimumFractionDigits: accuracy,
      maximumFractionDigits: accuracy,
      useGrouping: grouping,
    });
}

function padZeroes (value, digits = 6) {
  if (typeof value === 'undefined') {
    return '---';
  }
  return Number(value)
    .toLocaleString(undefined, {
      minimumIntegerDigits: digits,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: false,
    });
}

function id (value) {
  if (typeof value === 'undefined') {
    return '---';
  }
  return Number(value)
    .toLocaleString(undefined, {
      minimumIntegerDigits: 6,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: false,
    });
}

function fileSize (bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;

  const dm    = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i     = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export {finance, id, fileSize, padZeroes};
